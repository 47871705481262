.card{
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid  rgba(112, 112, 112, 0.15);
    margin-bottom: 25px;
    box-sizing: border-box;
}
.card.xe{
    width: 50% !important;
}
.card:last-child{
    margin-bottom: 0;
}

.card-header{
    padding: 16px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid  rgba(112, 112, 112, 0.15);
    display: flex;
    justify-content: space-between;
}
.card-footer{
    padding: 8px 20px;
    box-sizing: border-box;
    border-top: 1px solid  rgba(112, 112, 112, 0.15);
    display: flex;
    justify-content: space-between;
}
.card-header h3{
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 500;
    height: 24px;
    color: #333;
}
.card-body{
    padding: 16px 20px;
    box-sizing: border-box;
}

@media only screen and (max-width: 920px){
    .card{
        width: 100%;
    }
    .card.xe{
        width: 100% !important;
    }
    .card-header,
    .card-body{
        padding: 18px;
    }
}