@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
}

:root{
    --primary: #333;
    --blue: #1D7ABF;
    --red: #e53935;
    --green: #00FF00;
}
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #888;
    box-sizing: border-box;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary);
}
html{
    height: -webkit-fill-available;
}
body{
    background: #ffffff;
    height: 100%;
    display: flex; 
    flex-direction: column;
    margin: 0;
    min-height: -webkit-fill-available;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Rubik', sans-serif;
    cursor: default;
}
p, a, li{
    font-family: 'Roboto', sans-serif;
    cursor: default;
    color: #333;
    text-decoration: none;
}
.d-mb{
    margin-bottom: 16px;
}
.row{
    display: flex;
    flex-direction: row;
}
.col-30{
    width: 30%;
}
.col-10{
    width: 10%;
}
.space-between{
    justify-content: space-between;
}
.card-row{
    display: flex;
    width: 100%;
    height: 28px;
}
.card-row .card-row-title{
    width: 50%;
    color: #333;
    font-size: 11px;
    line-height: 2em;
    font: 400 14px/20px Lato,sans-serif;
}
.card-row .card-row-content{
    width: 50%;
    color: #333;
    font-size: 11px;
    line-height: 2em;
    font: 400 14px/20px Lato,sans-serif;
    font-weight: 700;
}
.card-row .card-row-content.red{
    color: var(--red) !important;
}
.card-row .card-row-content.green{
    color: var(--green) !important;
}
.padding{
    padding: 20px;
    box-sizing: border-box;
}

.fading{
    animation-timing-function: ease;
    animation-name: fading;
    animation-duration: 0.5s;
}
@keyframes fading {
    from {opacity: 0;}
    to {opacity: 1;}
}
.css-yup6gp-MuiButtonBase-root-MuiButton-root,
.css-1udjbr0-MuiButtonBase-root-MuiButton-root{
    text-transform: none !important;
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
    color: #333 !important;
}
/* Global Materail ui */
.css-cjctlb-MuiButtonBase-root-MuiTab-root{
    text-transform: none !important;
}
.tab-app .css-cjctlb-MuiButtonBase-root-MuiTab-root,
.tab-app .css-wigwbp{
    padding: 24px 28px !important;
}
/* Container */
.container{
    display: flex;
    height: 100% !important;
    overflow: hidden;
    min-height: -webkit-fill-available;
}
.app{
    width: 100%;
    position: relative;
    overflow-y: auto;
}

/* Tabs */
.tab-container{
    height: calc(100vh - 130px);
}

/* TAble */
.css-jtlhu6-MuiTablePagination-root:last-child {
    padding: 0;
    background-color: #fff;
    position: sticky !important;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgb(0 0 0 / 10%);
}
.css-14covj4-MuiToolbar-root{
    position: sticky !important;
    top: 0 !important;
    background-color: #fff;
    z-index: 999;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    box-sizing: border-box;
}
.css-15wwp11-MuiTableHead-root,
.css-1wbz3t9{
    background-color: #333 !important;
}
.css-15wwp11-MuiTableHead-root .css-10dfkli-MuiTableCell-root,
.css-15wwp11-MuiTableHead-root .css-1azl6jz-MuiTableCell-root,
.css-15wwp11-MuiTableHead-root .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active,
.css-15wwp11-MuiTableHead-root .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon,
.css-15wwp11-MuiTableHead-root .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root:hover,
.css-15wwp11-MuiTableHead-root .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root,
.css-1bigob2,
.css-dx096b.Mui-active,
.css-dx096b.Mui-active .MuiTableSortLabel-icon,
.css-dx096b:hover{
    color: #fff !important;
}
.css-15wwp11-MuiTableHead-root .css-1ombvec-MuiButtonBase-root-MuiCheckbox-root{
    color: #fff !important;
}
.MuiTableContainer-root{
    height: calc(100vh - 182px);
}
.tab-app .MuiTableContainer-root{
    height: calc(100vh - 248px);
}
.full-height-table .MuiTableContainer-root{
    height: calc(100vh - 182px);
}

.css-1t29gy6-MuiToolbar-root{
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 16px;
}
.css-1t29gy6-MuiToolbar-root{
    min-height: auto !important;
}
.css-1mxz8qt-MuiPaper-root,
.css-1pty0ct{
    border-radius: 0 !important;
    margin-bottom: 0 !important;
}
/* Calendar */
.fc-direction-ltr {
    height: calc(100vh - 64px);
}
.fc .fc-toolbar-title{
    color: #333;
}
.fc .fc-toolbar.fc-header-toolbar{
    margin-bottom: 0 !important;
    padding: 20px;
    box-sizing: border-box !important;
}
.fc .fc-button-primary{
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
    outline: 0 !important;
}

/* Login page */
.login-container{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #fff;
}
.login-container p,
.login-container h1,
.login-container h2,
.login-container h3,
.login-container h4{
    color: #333 !important;
}

.login-card{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    max-width: 500px;
    width: 100%;
}

.login-card h1{
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
}


.login-card .right-button-container{
    display: flex;
    justify-content: space-between;
}
.login-card .right-button-container p{
    color: #333;
}
.login-card .right-button-container p a{
    color: #5685DF;
}
.login-card .copy-right-text{
    margin-top: 40px;
    text-align: center;
}

/* special-table */

.special-table {
    border-collapse: collapse;
    width: 100%;
}
  
.special-table td, .special-table th {
    padding: 0 8px;
}
  
.special-table td{
    font-family: Lato,sans-serif;
    color: rgba(0,0,0,.87);
}
.special-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: white;
    font: 400 13.3333px Arial;
    color: rgba(0,0,0,.54);
}
.special-table .tb{
    height: 70px; 
}
.special-table .inline{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px; 
}
.special-table .inline-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 70px; 
}
/* add-img-container */
.add-img-container{
    width: 120px;
    height: 120px;
    border: 1px dashed black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}
.add-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.float-action-button{
    position: fixed !important;
    bottom: 32px !important;
    right: 32px !important;
    z-index: 800 !important;
}

.MuiAlert-filledError {
    background-color: #d32f2f !important;
    position: fixed;
    right: 104px;
    bottom: 32px;
    animation-name: alertView;
    animation-duration: 0.2s;
    animation-iteration-count: alternate ;
}

@keyframes alertView {
    from {
        right: -100%;
    }

    to {
        right: 24px;
    }
}

@media only screen and (max-width: 920px){
    .MuiToolbar-root{
        height: 64px !important;
    }
    .MuiTablePagination-root{
        height: 53px !important;
    }
    .MuiTablePagination-root .MuiToolbar-root{
        height: 52px !important;
    }
}

@media only screen and (max-width: 920px){
    .padding{
        padding: 8px;
    }
    .float-action-button{
        bottom: 16px !important;
        right: 16px !important;
    }
    .float-action-button.table-type{
        bottom: 48px !important;
    }
}